﻿const scrollEffect = () => {
  const headerElement = document.querySelector(
    "[data-js-menu=hide-on-scroll]"
  ) as HTMLElement;

  let headerVisible = true;
  let scrollPosition = 0;

  const getHeaderHeight = () => {
    let header = document.getElementById("site-header") as HTMLElement;
    return header.getBoundingClientRect().height;
  }

  // Sync the two variables that setup the header scroll and main top padding
  // these are separate to prevent odd scroll effect of content
  // expanding when they share the same global variable that is dynamically updated.
  const initHeaderHeight = () => {
    if (!headerElement) {
      return;
    }

    let headerHeight = getHeaderHeight();
    document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    document.documentElement.style.setProperty("--main-headroom", `${headerHeight}px`);

  }

  const focusWithinMenuOrMenuOpen = () => {
    if (isMobile()) {
      return headerElement?.dataset.menuOpen === "true";
    } else {
      return headerElement?.contains(document.activeElement);
    }
  };

  const isAtTopOfScreen = () => {
    if (!headerElement) {
      return;
    }

    if (window.scrollY === 0) {
      headerElement.dataset.headerScrolled = "false";
    }
    else {
      headerElement.dataset.headerScrolled = "true";
    }
  }

  const toggleNavigationOnScroll = () => {
    if (!headerElement) {
      return;
    }

    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition < scrollPosition) {
      // up
      if (!headerVisible) {
        headerVisible = true;
        headerElement.style.transform = "";
        document.documentElement.style.setProperty("--header-height", `${getHeaderHeight()}px`);
      }
    } else {
      // down
      if (currentScrollPosition > 60 && headerVisible) {
        if (!focusWithinMenuOrMenuOpen()) {
          headerVisible = false;
          headerElement.style.transform = `translateY(-100%)`;
          document.documentElement.style.setProperty("--header-height", "0px");
        }
      }
    }

    scrollPosition = currentScrollPosition;
  };

  const initScrollEffects = () => {
    initHeaderHeight();
    isAtTopOfScreen();
  };

  headerElement?.addEventListener("focusin", () => {
    if (!headerVisible) {
      // un-hide header on focus in
      headerElement.style.transform = "";
    }
  });

  window.addEventListener("scroll", toggleNavigationOnScroll, {
    passive: true,
  });

  window.addEventListener("DOMContentLoaded", initScrollEffects);

  let timeout: number | undefined = undefined,
    delay = 250;

  window.addEventListener('resize', () => {
    clearTimeout(timeout);

    timeout = setTimeout(initHeaderHeight, delay);
  });

  window.addEventListener("scroll", isAtTopOfScreen, {
    passive: true,
  });
}

export const isMobile = () => window.innerWidth < 1280;

const preventBodyScroll = (e: Event) => {
  const currentlyOpenMenu = document.querySelector("a[aria-expanded='true'] + section[data-js-panel]") as HTMLElement;
  const headerElement = document.getElementById("site-header") as HTMLElement;
  const target = e.target as HTMLElement;

  const headerHeight: number = headerElement.getBoundingClientRect().height;
  const menuHasOverflow = currentlyOpenMenu.getBoundingClientRect().height == (window.innerHeight - headerHeight - 16);
  const isOverlay = target.dataset.jsMenu == "overlay";

  const scrollingInMenu = currentlyOpenMenu.contains(e.target as Node);
  const scrollingInHeader = headerElement.contains(e.target as Node);

  if ((scrollingInMenu && !menuHasOverflow) || isOverlay || (scrollingInHeader && !scrollingInMenu)) {
    e.preventDefault();
  }
}

export const disablePageScroll = () => {
  window.addEventListener("wheel", preventBodyScroll, { passive: false });
  window.addEventListener("touchmove", preventBodyScroll, { passive: false });

}

export const enablePageScroll = () => {
  window.removeEventListener("wheel", preventBodyScroll);
  window.removeEventListener("touchmove", preventBodyScroll);
}

scrollEffect();