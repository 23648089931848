﻿import { enablePageScroll, disablePageScroll } from "./scrollEffect";

const desktopMenu = () => {
  const headerElement = document.querySelector(
    "[data-js-menu=hide-on-scroll]"
  ) as HTMLElement;

  const closeOnEscapeHandler = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      closeAllPanels();
    }
  };

  const closePanel = (panel: HTMLElement) => {
    const link = panel.previousElementSibling as HTMLElement;
    const topLink = link.parentElement as HTMLElement;
    link?.setAttribute("aria-expanded", "false");
    topLink?.setAttribute("aria-expanded", "false");
    closeOverlay();
    enablePageScroll();
  };

  const closeOverlay = () => {
    var overlay = document.querySelector("[data-js-menu=overlay]") as HTMLElement;
    overlay.setAttribute("aria-hidden", "true");
  }

  const openOverlay = () => {
    var overlay = document.querySelector("[data-js-menu=overlay]") as HTMLElement;
    overlay.setAttribute("aria-hidden", "false");
  }

  const closeAllPanels = () => {
    document
      .querySelectorAll("[data-js-panel]")
      .forEach(panel => closePanel(panel as HTMLElement));
  };

  const openPanel = (panel: HTMLElement) => {
    const link = panel.previousElementSibling as HTMLElement;
    const topLink = link.parentElement as HTMLElement;
    link?.setAttribute("aria-expanded", "true");
    topLink?.setAttribute("aria-expanded", "true");
    openOverlay();
    disablePageScroll();
  };

  const toggleMenuHandler = (e: Event) => {
    const link = e.currentTarget as HTMLElement;
    const wasOpen: boolean = link.getAttribute("aria-expanded") === "true";
    const menuId = link.getAttribute("aria-controls");
    if (!menuId) {
      console.error("Missing id in aria-controls attribute of menu item");
      return;
    }

    const panel = document.getElementById(menuId);
    if (!panel) {
      console.error(`Could not find panel: ${menuId}`);
      return;
    }

    closeAllPanels();
    wasOpen ? closePanel(panel) : openPanel(panel);
    link.setAttribute("aria-expanded", (!wasOpen).toString().toLowerCase());

    if (headerElement) {
      // shared state with scrollEffect
      headerElement.dataset.menuOpen = (!wasOpen).toString();
    }
  };

  // Prevent navigation when clicking on a menu item which should open a submenu
  document
    .querySelectorAll("[data-js-menu-prevent-navigation]")
    .forEach(link => link.addEventListener("click", (e: Event) => {
      e.preventDefault();
      toggleMenuHandler(e);
    }));

  const escapablePanels = document.querySelectorAll("[data-js-menu=close-on-esc]");

  if (escapablePanels) {
    window.addEventListener("keydown", e => closeOnEscapeHandler(e as KeyboardEvent))
  }

  (document.querySelector("[data-js-menu=overlay]") as HTMLElement)
    .addEventListener("click", closeAllPanels);
};

desktopMenu();