﻿import { isMobile } from "./scrollEffect";

const mobileMenu = () => {
  const headerElement = document.querySelector(
    "[data-js-menu=hide-on-scroll]"
  ) as HTMLElement;

  const closeMobileMenu = (mobileMenu: HTMLElement) => {
    if (mobileMenu) {
      mobileMenu.dataset.expanded = "false";
    }
  };

  const openMobileMenu = (mobileMenu: HTMLElement) => {
    if (mobileMenu) {
      mobileMenu.dataset.expanded = "true";
    }
  };

  const toggleMobileMenuHandler = (e: Event) => {
    const btn = e.currentTarget as HTMLElement;
    const wasOpen: boolean = btn.getAttribute("aria-expanded") === "true";
    const mobileMenuId = btn.getAttribute("aria-controls");
    if (!mobileMenuId) {
      console.error("Missing id in aria-controls attribute of toggle button");
      return;
    }

    const mobileMenu = document.getElementById(mobileMenuId);
    if (!mobileMenu) {
      console.error(`Could not find mobile menu: ${mobileMenuId}`);
      return;
    }

    wasOpen ? closeMobileMenu(mobileMenu) : openMobileMenu(mobileMenu);
    btn.setAttribute("aria-expanded", (!wasOpen).toString().toLowerCase());

    if (headerElement) {
      // shared state with scrollEffect
      headerElement.dataset.menuOpen = (!wasOpen).toString();
    }
  };

  // Bind mobile menu events

  let toggleButton = document.querySelector("[data-js-menu=toggle-button]") as HTMLElement;
  toggleButton.addEventListener("click", toggleMobileMenuHandler);

  // Swipe to close

  let touchStartX: number, touchEndX: number;

  let mobilePanel = document.querySelector("[data-js-menu=mobile-panel]") as HTMLElement;

  mobilePanel.addEventListener(
    'touchstart',
    e => (touchStartX = e.targetTouches[0].clientX),
    { passive: true }
  );

  mobilePanel.addEventListener(
    'touchmove',
    e => (touchEndX = e.targetTouches[0].clientX),
    { passive: true }
  );

  mobilePanel.addEventListener('touchend', e => {
    if (touchEndX != 0 && touchStartX - touchEndX > 50) {
      toggleButton.dispatchEvent(new Event('click'));
    }
    touchStartX = 0, touchEndX = 0;
  }, { passive: true });

  // Accordion

  const toggleMobileMenuControllHandler = (e: Event) => {
    const control = e.currentTarget as HTMLElement;
    const link = control.previousElementSibling as HTMLElement;
    const wasOpen: boolean = control.getAttribute("aria-expanded") === "true";
    const menuId = control.getAttribute("aria-controls");
    if (!menuId) {
      console.error("Missing id in aria-controls attribute of menu item control");
      return;
    }

    const menu = document.getElementById(menuId);
    if (!menu) {
      console.error(`Could not find panel: ${menuId}`);
      return;
    }

    wasOpen ? closeMobileMenu(menu) : openMobileMenu(menu);
    control.setAttribute("aria-expanded", (!wasOpen).toString().toLowerCase());
    link.setAttribute("aria-expanded", (!wasOpen).toString().toLowerCase());
  }

  // Bind menu controls to accordion 

  document.querySelectorAll("[data-js-menu=menu-item-control]")
    .forEach(control => control.addEventListener("click", (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      toggleMobileMenuControllHandler(e);
    }))
};

mobileMenu();